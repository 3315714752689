import ResponsiveDrawer from "./ResponsiveDrawer";
import { UpcomingArticleBanner } from "./UpcomingArticleBanner";
import { Outlet } from "react-router-dom";
import { Suspense } from "react";
import Loading from "./Loading";

export function Layout() {
  return (
    <>
      <UpcomingArticleBanner />
      <ResponsiveDrawer>
        <Suspense fallback={<Loading />}>
          <Outlet />
        </Suspense>
      </ResponsiveDrawer>
    </>
  );
}
