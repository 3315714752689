import { RouteObject } from "react-router-dom";
import { ArticleConfig } from "../types";

export const articleToRoute = ({
  path,
  Component,
}: ArticleConfig): RouteObject => {
  return {
    path,
    Component: () => <Component />,
  };
};
