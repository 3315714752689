const ONE_DAY_IN_MS = 1000 * 60 * 60 * 24;

export function convertMsToDays(count: number) {
  return count / ONE_DAY_IN_MS;
}

export function getFriendlyDate(date: Date = new Date()) {
  return date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
}

export function orderByDate(direction: "ASCENDING" | "DESCENDING") {
  return (firstDate?: Date, secondDate?: Date) => {
    const directionFlag = direction === "ASCENDING" ? 1 : -1;

    if (!firstDate && !secondDate) {
      return 0;
    }

    if (firstDate && secondDate) {
      return directionFlag * (firstDate.getTime() - secondDate.getTime());
    }

    if (firstDate) {
      return directionFlag;
    }

    return -directionFlag;
  };
}

export function isFutureDate(date?: Date) {
  const currentDate = new Date();
  if (date && date > currentDate) {
    return true;
  }

  return false;
}
