import "./App.css";
import ResponsiveDrawer from "./components/ResponsiveDrawer";
import {
  createBrowserRouter,
  RouteObject,
  RouterProvider,
} from "react-router-dom";
import ErrorPage from "./components/ErrorPage";
import { ALL_ARTICLES } from "./articles";
import { createTheme, ThemeProvider } from "@mui/material";
import { articleToRoute } from "./utils/articleToRoute";
import { Layout } from "./components/Layout";

const ALL_ROUTES: RouteObject[] = ALL_ARTICLES.map(articleToRoute);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: ALL_ROUTES,
  },
]);

const theme = createTheme({});

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
