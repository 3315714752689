import { Chip } from "@mui/material";
import { useState } from "react";
import { UPCOMING_BLOG_ARTICLES } from "../articles";
import { getFriendlyDate } from "../utils/date";

const NEXT_ARTICLE = UPCOMING_BLOG_ARTICLES.at(-1);

export function UpcomingArticleBanner() {
  const [isDeleted, setIsDeleted] = useState(false);

  const handleDelete = () => {
    setIsDeleted(true);
  };

  if (!NEXT_ARTICLE || isDeleted) {
    return null;
  }

  const { title, date } = NEXT_ARTICLE;
  const friendlyDate = getFriendlyDate(date);
  const upcomingArticleMessage = (
    <>
      <strong>{title}</strong>
      {` will be released on `}
      <strong>{friendlyDate}</strong>
    </>
  );

  return (
    <Chip
      label={upcomingArticleMessage}
      variant="outlined"
      onDelete={handleDelete}
      sx={{
        position: "fixed",
        right: 0,
        top: 0,
        mt: 2,
        mr: 2,
        py: 1,
        height: "auto",
        maxWidth: "calc(100vw - 70px)",
        bgcolor: "white",
        zIndex: 5,
        "& .MuiChip-label": {
          display: "block",
          whiteSpace: "normal",
        },
      }}
    />
  );
}
