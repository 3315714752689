import { ExpandLess, ExpandMore, SvgIconComponent } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ArticleConfig } from "../types";

interface CollapsibleArticleListProps {
  articles: ArticleConfig[];
  title: string;
  icon: SvgIconComponent;
  onClick?: () => void;
}

export function CollapsibleArticleList(props: CollapsibleArticleListProps) {
  const { articles, title, icon: Icon, onClick } = props;
  const [isCollapsed, setIsCollapsed] = useState(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const collapseButton = (
    <ListItemButton onClick={() => setIsCollapsed((prev) => !prev)}>
      <ListItemIcon>
        <Icon fontSize="small" />
      </ListItemIcon>
      <ListItemText primary={title} />
      {isCollapsed ? <ExpandMore /> : <ExpandLess />}
    </ListItemButton>
  );

  return (
    <>
      {collapseButton}
      <Collapse in={!isCollapsed} timeout="auto">
        <List disablePadding>
          {articles.map(({ path, title, date }) => {
            const key = `${title}_${date?.getTime()}`;

            return (
              <ListItemButton
                key={key}
                component={Link}
                to={path}
                onClick={onClick}
                selected={currentPath === path}
                dense={true}
              >
                <ListItemIcon
                  sx={{
                    visibility: "hidden",
                  }}
                >
                  <Icon fontSize="small" />
                </ListItemIcon>
                <ListItemText secondary={title} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}
