import { ArticleConfig } from "../types";
import { convertMsToDays } from "./date";

export function decorateArticleTitles(articles: ArticleConfig[]) {
  return articles.map((article) => {
    const { title, date = new Date() } = article;
    const elapsedDays = convertMsToDays(Date.now() - date.getTime());

    if (elapsedDays < 7) {
      return {
        ...article,
        title: `🆕 ${title}`,
      };
    }

    return {
      ...article,
    };
  });
}
