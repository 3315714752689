import { useState, useEffect, ReactNode } from "react";
import { LIVE_BLOG_ARTICLES, GETTING_STARTED_ARTICLES } from "../articles";
import {
  Menu as MenuIcon,
  Feed as FeedIcon,
  WavingHand as WavingHandIcon,
} from "@mui/icons-material";
import {
  IconButton,
  Drawer,
  Box,
  List,
  Typography,
  useMediaQuery,
  useTheme,
  CssBaseline,
} from "@mui/material";
import { CollapsibleArticleList } from "./CollapsibleArticleList";
import { decorateArticleTitles } from "../utils/decorateArticleTitles";

const LOGO_URL = `${process.env.PUBLIC_URL}/apple-touch-icon.png`;

const DRAWER_WIDTH = 240;

const DRAWER_ARTICLES = decorateArticleTitles(LIVE_BLOG_ARTICLES);

interface ResponsiveDrawerProps {
  children: ReactNode;
}

export default function ResponsiveDrawer(props: ResponsiveDrawerProps) {
  const [isMobileOpen, setIsMobileOpen] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // When screen is shrunk to mobile width, we start
  // the drawer to in the closed position.
  useEffect(() => {
    if (isMobile) {
      setIsMobileOpen(false);
    }
  }, [isMobile]);

  const handleDrawerToggle = () => {
    setIsMobileOpen((prev) => !prev);
  };

  const drawer = (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "8px",
          py: 2,
          bgcolor: "#9dd8f9",
        }}
      >
        <img
          src={LOGO_URL}
          alt="logo"
          width={40}
          style={{
            borderRadius: "20%",
          }}
        />
        <Typography variant="h6" noWrap component="div" textAlign="center">
          AiMention.com
        </Typography>
      </Box>
      <List
        sx={{
          py: 0,
        }}
      >
        <CollapsibleArticleList
          articles={GETTING_STARTED_ARTICLES}
          title="Getting Started"
          icon={WavingHandIcon}
          onClick={() => setIsMobileOpen(false)}
        />

        <CollapsibleArticleList
          articles={DRAWER_ARTICLES}
          title="Articles"
          icon={FeedIcon}
          onClick={() => setIsMobileOpen(false)}
        />
      </List>
    </>
  );

  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiListItemIcon-root": {
          minWidth: "35px",
        },
      }}
    >
      <CssBaseline />
      <Box
        component="nav"
        sx={{
          width: { sm: DRAWER_WIDTH },
          flexShrink: { sm: 0 },
          zIndex: 0,
          // bgcolor: "#262F37",
        }}
        aria-label="articles"
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none" }, position: "fixed", m: 1 }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: DRAWER_WIDTH,
              // bgcolor: "#cde0e9",
            },
          }}
          open={isMobile ? isMobileOpen : true}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
          minHeight: "100vh",
          bgcolor: "#f4f5f6",
          display: "flex",
          justifyContent: "center",
          p: 7,
        }}
      >
        <Box
          sx={{
            maxWidth: "920px",
          }}
        >
          {props.children}
        </Box>
      </Box>
    </Box>
  );
}
