import { ArticleConfig } from "../types";
import { WELCOME_CONFIG } from "./welcome";
import { HOW_AI_IS_CHANGING_THE_JOB_MARKET_CONFIG } from "./how-ai-is-changing-the-job-market";
import { HOW_AI_IS_REVOLUTIONIZING_HEALTHCARE_CONFIG } from "./how-ai-is-revolutionizing-healthcare";
import { SHOULD_WE_PAUSE_AI_CONFIG } from "./should-we-pause-ai";
import { THE_FUTURE_OF_AI_CONFIG } from "./the-future-of-ai";
import { UNDERSTANDING_DEEPFAKES_CONFIG } from "./understanding-deepfakes";
import { WHAT_IS_AI_CONFIG } from "./what-is-ai";
import { HOW_AI_IMPACTS_STUDENTS_CONFIG } from "./how-ai-impacts-students";
import { isFutureDate, orderByDate } from "../utils/date";
import { AI_TRENDS_IN_2023_CONFIG } from "./ai-trends-in-2023";
import { HOW_AI_CHATBOTS_ARE_CHANGING_THE_WAY_WE_SEARCH_THE_WEB_CONFIG } from "./how-ai-chatbots-are-changing-the-way-we-search-the-web";
import { WHAT_IS_AGI_CONFIG } from "./what-is-artificial-general-intelligence";
import { HOW_SELF_DRIVING_CARS_WORK_CONFIG } from "./how-self-driving-cars-work";

const orderByDateDescending = orderByDate("DESCENDING");

export const GETTING_STARTED_ARTICLES: ArticleConfig[] = [
  WELCOME_CONFIG,
  WHAT_IS_AI_CONFIG,
  THE_FUTURE_OF_AI_CONFIG,
];

const BLOG_ARTICLES: ArticleConfig[] = [
  SHOULD_WE_PAUSE_AI_CONFIG,
  HOW_AI_IS_REVOLUTIONIZING_HEALTHCARE_CONFIG,
  HOW_AI_IS_CHANGING_THE_JOB_MARKET_CONFIG,
  UNDERSTANDING_DEEPFAKES_CONFIG,
  HOW_AI_IMPACTS_STUDENTS_CONFIG,
  AI_TRENDS_IN_2023_CONFIG,
  HOW_AI_CHATBOTS_ARE_CHANGING_THE_WAY_WE_SEARCH_THE_WEB_CONFIG,
  WHAT_IS_AGI_CONFIG,
  HOW_SELF_DRIVING_CARS_WORK_CONFIG,
].sort((a, b) => orderByDateDescending(a.date, b.date));

export const LIVE_BLOG_ARTICLES = BLOG_ARTICLES.filter(
  (article) => !isFutureDate(article.date)
);

export const UPCOMING_BLOG_ARTICLES = BLOG_ARTICLES.filter((article) =>
  isFutureDate(article.date)
);

export const ALL_ARTICLES = [...GETTING_STARTED_ARTICLES, ...BLOG_ARTICLES];
